<template>
    <div ref="question">
        <h3 class="md:text-[30px] text-[20px] font-bold mb-[30px]">{{ $store.state.current.question }}</h3>

        <div class="grid md:grid-cols-[1fr_1fr] gap-[30px] mb-[30px]">

            <AnswerCard v-for="(answer, key) in $store.state.current.answers"
                ref="answer"
                :key="key"
                :leater="key"
                :answer="answer"
                :selected="selected === key"
                @click="selection(key)" />

        </div>
    </div>
    
    <Transition name="fade">
        <Button ref="apply" v-show="!$store.state.direction || isSubmit()" class="min-w-[158px]" :color="isSubmit() ? 'violet' : 'white'" @click="applyAnswer()">
            {{ isSubmit() ? 'SOUMETTRE' : 'OK' }}
            <font-awesome-icon :class="isSubmit() ? 'text-white' : 'text-violet-500'" icon="fa-regular fa-check" />
        </Button>
    </Transition>

</template>

<script>
import {gsap, Power2} from 'gsap';
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

import Button from '@/components/Button.vue';
import AnswerCard from './AnswerCard.vue';

    export default {
        name: 'QuestionComponent',
        data() {
            return {
                selected: false,
            }
        },
        components: {
            Button,
            AnswerCard
        },
        watch: {
            '$store.state.progress': function () {

                // Prev selection
                if (this.$store.state.answers.length) {
                    const answer = this.$store.state.answers[this.$store.state.progress - 1];

                    if (answer) {
                        this.selected = answer;
                    } else if (typeof answer === 'undefined') {
                        this.selected = false;
                    }
                }

                this.animation();

            }
        },
        mounted() {
            gsap.registerPlugin(ScrollToPlugin);
        },
        methods: {
            isSubmit() {
                return this.$store.state.answers.length === this.$store.state.total;
            },
            selection(key) {
                
                this.selected = key;

                this.$store.commit('apply', this.selected);

                if (window.innerWidth < 576) {
                    this.scrollTo(this.isSubmit() ? this.$refs.apply.$refs.button : 0);

                }

                if (!this.$store.state.direction || this.$store.state.progress === this.$store.state.total) return;

                this.selected = false;

                this.$store.commit('next');

            },

            scrollTo(pos) {
                gsap.to(window, {
                    duration: .3,
                    scrollTo: {
                        y: pos,
                    }
                });
            },

            applyAnswer() {

                if (!this.selected) return;
                
                this.$store.commit('apply', this.selected);

                this.selected = false;

                this.$store.commit('next');

                if (window.innerWidth < 576) {
                    this.scrollTo(0)
                }
            },

            textHeight() {
                // answer text height
                if (!this.$refs.answer.length) return;

                const textA = this.$refs.answer[0].$refs.text;
                const textB = this.$refs.answer[1].$refs.text;

                const rectA = textA.getBoundingClientRect();
                const rectB = textB.getBoundingClientRect();

                if (rectA.height > rectB.height) {
                    textB.style.height = rectA.height + 'px';

                    return;
                } else if (rectA.height < rectB.height) {
                    textA.style.height = rectB.height + 'px';

                    return;
                }

                textB.style.height = 'auto';
                textA.style.height = 'auto';
            },

            animation() {
                const tl = gsap.timeline();

                tl.to(this.$refs.question, .3, {
                    y: this.$store.state.direction ? '-50%' : '50%',
                    autoAlpha: 0,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        this.$store.commit('current');

                    }
                });

                tl.set(this.$refs.question, {
                    y: this.$store.state.direction ? '50%' : '-50%',
                    delay: .2
                });

                tl.add(() => {
                    const self = this;

                    setTimeout(() => {
                        self.textHeight();
                    }, 50)
                })

                tl.to(this.$refs.question, .3, {
                    y: '0%',
                    autoAlpha: 1
                });
            }
        }
    }
</script>

<style>

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>