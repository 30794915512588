<template>

    <div class="md:min-h-[100vh] min-h-[calc(100vh-84px)] bg-violet relative flex justify-center items-center z-[1]">
        <QuestionElement className="top-[12.2%] left-[17.8%] rotate-[-30deg]" />
        <QuestionElement className="top-[8.8%] right-[17.8%] rotate-[-30deg]" />
        <QuestionElement className="top-[33%] left-[22%] rotate-[30deg]" />
        <QuestionElement className="top-[35%] right-[30%] rotate-[-15deg]" />
        <QuestionElement className="bottom-[20%] left-[30%] rotate-[-30deg]" />
        <QuestionElement className="bottom-[10.3%] right-[36.5%] rotate-[30deg]" />
        <QuestionElement className="bottom-[20.5%] right-[17%] rotate-[30deg]" />

        <div class="px-[20px] relative">
            <div class="relative">
                <div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <img ref="heroBg" src="@/assets/img/hero-bg.svg" />
                </div>
                <div class="w-[185px] mx-auto scale-x-[-1] translate-x-[-20%]">
                    <div ref="heroContainer"></div>
                </div>
            </div>

            <h1 class="md:text-[50px] text-[26px] text-white font-bold mb-[50px]">Quel est votre type <span class="font-serif italic">d'influenceur</span>?</h1>

            <div class="flex justify-center">
                <Button color="white" @click="showConfirmation">
                  Découvrez vos superpouvoirs
                    <font-awesome-icon icon="fa-regular fa-arrow-right" />
                </Button>
            </div>
        </div>
    </div>
  <confirm-popup
      :visible="isModalVisible"
      @confirm="handleConfirm"
  />
</template>

<script>
import QuestionElement from '@/components/QuestionElement.vue';
import Button from '@/components/Button.vue';

import { gsap } from 'gsap';
import lottie from "lottie-web";

import animationData from '@/assets/lottie/hero.json';
import ConfirmPopup from "@/components/ConfirmPopup.vue";

    export default {
        name: 'HelloPage',
        components: {
          ConfirmPopup,
            QuestionElement,
            Button,
        },
        data() {
          return {
            isModalVisible: false
          };
        },
        methods: {
          showConfirmation() {
            this.isModalVisible = true;
          },
          handleConfirm(selectedOption) {
            this.isModalVisible = false;
              this.$store.commit('showQuiz', {selectedOption:selectedOption});
          },
        },
        mounted() {

            lottie.loadAnimation({
                container: this.$refs.heroContainer,
                animationData: animationData,
                autoplay: true,
                loop: true,
                width: 185
            });

            document.addEventListener('mousemove', e => {

                if (this.$store.state.quizView || this.$store.state.resultView) return;

                const rect = document.body.getBoundingClientRect();

                const docH = rect.height;
                const docW = rect.width;

                const x = e.pageX - docH;
                const y = e.pageY - docW;

                gsap.to(this.$refs.heroContainer, 1, {
                    x: (x / 80),
                    y: (y / -100)
                })

                gsap.to(this.$refs.heroBg, 1, {
                    x: (x / 80),
                    y: (y / 100)
                })

            });
        }
    }
</script>