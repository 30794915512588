<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-container">
      <p>Êtes-vous Sanofien?</p>
      <div class="main-answer-container ">
      <div class="continer-answer">
        <input type="radio" id="yes" value="yes" v-model="selectedOption">
        <label for="yes">Oui</label>
      </div>
      <div class="continer-answer">
        <input type="radio" id="no" value="no" v-model="selectedOption">
        <label for="no">Non</label>
      </div>
      </div>
      <Button color="violet" class="central-item" :disabled="!selectedOption" @click="startQuiz">
        Commencer le quiz
        <font-awesome-icon icon="fa-regular fa-arrow-right" />
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
export default {
  components: {
    Button
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: null
    };
  },
  methods: {
    startQuiz() {
      this.$emit('confirm', this.selectedOption);
    }
  }
};
</script>

<style scoped>

@media (max-width: 450px) {
  body .main-answer-container {
    padding: 0px 5vw;
  }
}

.continer-answer label{
  font: normal normal normal 20px/24px Sanofi Sans;
  vertical-align: text-bottom;
  margin-left:9px;
}

.continer-answer input{
  width:20px;
  height:20px;
}

.main-answer-container{
  display: flex;
  justify-content: space-between;
  padding: 0px 60px;
  margin-bottom:40px;
}
.central-item{
  margin: 0 auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1;
}
.modal-container {
  padding: 40px 55px;
  text-align: center;
  max-width:420px;
  width:90%;
  background: #F4F2F5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 3px;
  height:230px;
}

.modal-container p{
  text-align: center;
  font: normal normal bold 20px/24px Sanofi Sans;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom:16px;
}

.continer-answer{
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.continer-answer input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 27px;
  height: 27px;
  padding: 5px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

.continer-answer input:checked {
  background-color: #7a00e6;
  border: 2px solid #7a00e6;
}

</style>
